// @js-import "@common/common-dependencies.js"
// @js-import "@shared/height.js"

ComponentsManager.register('teaser_tiles', function() {
    var HeightUtil = rwd.HeightUtil,
        ScreenSize = rwd.ScreenSize;
    return {
        init: function(componentId, $componentElement){
            $(window).onPage(ScreenSize.SIZE_CHANGE_EVENT_NAME, function(){
                var row = $componentElement.closest(".layout_row");
                if(!$componentElement.parents(".list_of_tiles").length){
                    if(ScreenSize.is(ScreenSize.SIZES.MEDIUM) || ScreenSize.is(ScreenSize.SIZES.LARGE)){
                        row.addClass("tiles_sync_height");
                        HeightUtil.resetAndSyncHeightInSets(HeightUtil.itemsBySelector(row, ".teaser_tiles .teaser_tiles_inner .description"));
                    } else {
                        row.removeClass("tiles_sync_height");
                        HeightUtil.resetHeightInSets(HeightUtil.itemsBySelector(row, ".teaser_tiles .teaser_tiles_inner .description"));
                    }
                }
            }).trigger(ScreenSize.SIZE_CHANGE_EVENT_NAME);
            $(window).onPage(ScreenSize.RESIZE_EVENT_NAME, function(){
                // Przestrzeń na dole kafelka dla przycisków pozycjonowanych absolutnie
                // (czasem łamią się do dwóch linii). Padding dolny równy wysokości przycisków.
                var elementWithPadding = $componentElement.find('.tile_content'),
                    buttonsContainer = $componentElement.find('.tile_content > .buttons'),
                    buttonsSelector = 'a, .button',
                    wrapperWidth = 0,
                    // początkowe wartości uwzględniają marginesy
                    buttonsWidth = 45,
                    buttonsHeight = 24,
                    cssPadding = '';

                if (buttonsContainer.length && buttonsContainer.find(buttonsSelector).length === 2) {
                    wrapperWidth = buttonsContainer.width();
                    buttonsHeight += buttonsContainer.height();
                    buttonsContainer.find(buttonsSelector).each(function(j, button) {
                        buttonsWidth += $(button).width(true);
                    });
                    if (buttonsWidth > wrapperWidth) {
                        cssPadding = buttonsHeight + 'px';
                    }
                    elementWithPadding.css('padding-bottom', cssPadding);
                }

                var row = $componentElement.closest(".layout_row.tiles_sync_height");
                if(!$componentElement.parents(".list_of_tiles").length){
                    HeightUtil.resetAndSyncHeightInSets(HeightUtil.itemsBySelector(row, ".teaser_tiles .teaser_tiles_inner > div, #kalkulator_pozyczkowy > .calc_inner"));
                }
            }).trigger(ScreenSize.RESIZE_EVENT_NAME);
            if (!ComponentsManager.isPreview()) {
                linkedContent($componentElement, ".clickable", ".header a");
                $componentElement.onPage("click", function(e){
                    if(e.which === 2 && e.target.tagName !== 'A' && $(e.target).parent().prop("tagName") !== 'A'){
                        e.preventDefault();
                        var newWindow = $componentElement.find('h3 a').attr('href');
                        window.open(newWindow, '_blank');
                    }
                });
            }
        }
    }
});
